<template>
  <div class="root">
    <el-row type="flex" class="header-block">
      <el-col :span="8">
        <div class="grid-content">
          <p class="header-text">Find a place to get involved</p>
        </div>
      </el-col>
      <el-col :span="8" class="search-filter-root">
        <div class="grid-content">
          <el-form @submit.native.prevent>
            <el-input
              id="searchInput"
              placeholder="Try a location or club name"
              v-model="searchInput"
              class="full-width"
            >
              <template slot="append">
                <button class="button-primary icon-wrapper" @click.prevent="fetchSearchData">
                  <i class="icon-search"></i>
                </button>
              </template>
            </el-input>
          </el-form>
        </div>
      </el-col>
      <el-col :span="8" class="search-filter-root">
        <div v-if="showFilterButtons" class="filters">
          <!-- below copied from element -->
          <div class="el-select select-1" :class="concatFilters.length !== 0 ? 'has-value' : null">
            <div
              class="el-input el-input--suffix"
              :class="filterVisible ? 'visible' : ''"
              @click="toggleVisible(true)"
            >
              <input
                type="text"
                readonly="readonly"
                autocomplete="off"
                :placeholder="filterText"
                class="el-input__inner"
              />
              <span class="el-input__suffix">
                <span class="el-input__suffix-inner">
                  <i class="el-select__caret el-input__icon icon-arrow-down"></i>
                </span>
              </span>
            </div>
            <ModalLayoutRelative
              v-if="filterVisible"
              class="modal-parent"
              @close="handleCancelFilters"
              title="FILTERS"
            >
              <FilterPage
                @submit="handleSubmitFilters"
                :value="appliedFiltersSync"
                @cancel="handleCancelFilters"
                @clear="handleClearFilters"
              />
            </ModalLayoutRelative>
            <div
              class="append-icon-close"
              v-show="concatFilters.length !== 0"
              @click="handleClearFilters"
            >
              <i class="icon-close-green"></i>
            </div>
          </div>
          <div v-if="hasPrograms" class="select-2">
            <el-select
              v-model="lengthValue"
              placeholder="DURATION"
              class="full-width"
              :class="lengthValue ? 'has-value' : null"
            >
              <el-option
                v-for="item in lengthChoices"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <div class="append-icon-close" v-show="lengthValue" @click="lengthValue = ''">
              <i class="icon-close-green"></i>
            </div>
          </div>
          <div v-if="hasPrograms" class="select-3">
            <el-select
              v-model="daysValue"
              placeholder
              class="mt-0 text-days full-width"
              multiple
              :style="dayOfTheWeekStyle"
              :class="daysValue.length !== 0 ? 'has-value' : null"
            >
              <el-option
                v-for="item in daysChoices"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <div class="append-icon-close" v-show="daysValue.length !== 0" @click="daysValue = []">
              <i class="icon-close-green"></i>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 2 columns list and rest map -->
    <el-row v-if="entities">
      <el-col :span="8" class="content card-list-root">
        <p class="sort-text">
          Sorted by closest to search location
        </p>
        <div class="tabs">
          <div class="tab-nav">
            <div class="tab"
                v-for="(tab, index) in tabs"
                :key="index"
                :class="{ active: activeTab === index }"
                @click="activeTab = index">
              {{ tab.name }}
            </div>
          </div>
          <div class="tab-content">
            {{ activeContent }}
          </div>
        </div>
        <!-- important: area for search results   -->
        <Card
          :id="`location_${object._id}`"
          v-for="(object, i) in renderContent"
          :object="object"
          :key="`${object._id}+${i}`"
          :ref="`location-${object._id}`"
          @submit="submit(object)"
          class="card"
        />
      </el-col>
      <el-col :span="16">
        <div id="map">
        </div>
      </el-col>
    </el-row>
    <div class="padding-lr-10-sm-and-up cant-find-card">
      <el-card class="mt-2 d-flex-column flex-0 align-text-center" v-if="cantFind">
        <div class="flex-1 align-text-center m-1">Can't find what you are looking for?</div>
        <el-button
          @click="handleCantFind"
          type="success"
          class="full-width button-light button-thin mw-300"
          >Get in contact</el-button
        >
      </el-card>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import flatMap from "lodash/fp/flatMap";
import reduce from "lodash/fp/reduce";
import find from "lodash/fp/find";
import filter from "lodash/fp/filter";
import omitBy from "lodash/fp/omitBy";
import isEmpty from "lodash/isEmpty";
import range from "lodash/range";
import { mapActions, mapGetters } from "vuex";
import Card from "@/components/card/CardBeta.vue";
import {
  daysChoices,
  lengthChoices,
  ageLevels,
  memberTypes,
  activity,
  competitionType,
  gender,
} from "@/utils/constants";
import ModalLayoutRelative from "@/layouts/ModalLayoutRelative.vue";
import FilterPage from "@/components/filters/FilterPage.vue";
import msg from "@/utils/constants/msg";

const appliedFiltersOriginal = {
  participantTypes: [],
  activities: [],
  genders: [],
  ages: [],
  competitionTypes: [],
};

export default {
  name: "ClubSearch",
  components: {
    FilterPage,
    ModalLayoutRelative,
    Card,
  },
  props: {
    criteria: String,
    type: Array,
    activity: Array,
    gender: String,
    agemin: Number,
    agemax: Number,
    comptype: Array,
    source: String,
    entity: Boolean,
    entitytype: String,
  },
  watch: {
    renderContent: function() {
      this.addEntitiesToMap();
    }
  },
  computed: {
    ...mapGetters("user", ["isAuthenticated"]),
    ...mapGetters("registration", ["current"]),
    activeContent() {
      if(this.entities)
        switch (this.activeTab) {
          case 0:
            this.renderContent = this.entities.programsData.concat(this.entities.clubsData, this.entities.associationsData, this.entities.teamsData);
            return;
          case 1:
            this.renderContent = this.entities.programsData;
            return;
          case 2:
            this.renderContent = this.entities.clubsData;
            return;
          case 3:
            this.renderContent = this.entities.associationsData;
            return;
          case 4:
            this.renderContent = this.entities.teamsData;
            return;
          default:
            this.renderContent = [];
            return;
        }
    },
    concatFilters() {
      const a = Object.values(this.appliedFiltersReal);
      return a.reduce((total, item) => total.concat(item), []);
    },
    filterText() {
      const result = this.concatFilters;
      if (result.length === 1 && result[0] !== "All") {
        return result[0].toUpperCase();
      }
      return "FILTER";
    },
    dayOfTheWeekStyle() {
      const { daysValue } = this;
      const txt = daysValue.length === 1 ? daysValue[0] : "DAY OF THE WEEK";

      return {
        "--content": `"${txt}"`.toUpperCase(),
        "--color": daysValue.length !== 0 ? "#098255" : "grey",
      };
    },
    currentAgeRange() {
      const ageLevel = find((o) => o.name === this.appliedFiltersReal.ages[0])(ageLevels);
      if (ageLevel) return { min: ageLevel.min, max: ageLevel.max };
      return {};
    },
    mappedParticipantType() {
      return flow(
        flatMap((val) => filter((o) => o.name.split(" ")[0] === val)(memberTypes)),
        map((obj) => obj.type)
      )(this.appliedFiltersReal.participantTypes);
    },
    showFilterButtons() {
      return this.entities?.length !== 0 || this.concatFilters.length !== 0;
    },
    hasPrograms() {
      return reduce((entity) => entity.entityType === "program")(this.entities).length !== 0;
    },
  },
  methods: {
    ...mapActions("registration", ["updateCurrent"]),

    handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
      infoWindow.open(map);
    },

    getCurrentGeoLocation() {
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              this.currentLocation = pos;
            },
            () => {
              this.handleLocationError(true, infoWindow, map.getCenter());
            }
          );
        } else {
          // If the browser doesn't support geolocation, show an error message
          alert('Geolocation is not supported by this browser.');
        }
    },

    addLocation(locations) {
      locations.forEach((entity) => {
        let position;
        if (entity.venue) {
          position = {
            lat: entity.venue.address.lat,
            lng: entity.venue.address.lng,
          };
        } else if (entity.contact) {
          position = {
            lat: entity.contact.address.lat,
            lng: entity.contact.address.lng,
          };
        }
        if(position) {
          const infowindow = new google.maps.InfoWindow({
            content: entity.name,
            ariaLabel: entity.name
          });
          const marker = new google.maps.Marker({
            position: {
              lat: position.lat,
              lng: position.lng,
            },
            map: this.map,
            title: entity.name,
            icon: {
              path: "M14.1406 11.3407C21.7949 3.68641 34.2049 3.68641 41.8592 11.3407C49.5135 18.995 49.5135 31.405 41.8592 39.0593L27.9999 52.9186L14.1406 39.0593C6.48633 31.405 6.48633 18.995 14.1406 11.3407ZM27.9999 30.8C31.0927 30.8 33.5999 28.2928 33.5999 25.2C33.5999 22.1072 31.0927 19.6 27.9999 19.6C24.9071 19.6 22.3999 22.1072 22.3999 25.2C22.3999 28.2928 24.9071 30.8 27.9999 30.8Z",
              fillColor: "#262626",
              fillOpacity: 1,
              scale: 0.5,
              anchor: new google.maps.Point(28, 56)
            }
          });
          marker.addListener("click", () => {
            this.scrollToCard(entity);
            infowindow.open({
              anchor: marker,
              map,
            });
          });
          this.markers.push(marker);
        }
      });
    },

    scrollToCard(location) {
      const card = this.$refs[`location-${location._id}`][0];
      card.$el.scrollIntoView({ behavior: 'smooth' });
    },

    addEntitiesToMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
          zoom: 10,
          // Default to NRL RLC
          center: { lat: this.currentLocation?.lat ? this.currentLocation.lat : -33.859953, lng: this.currentLocation?.lng ? this.currentLocation.lng : 151.256649 },
      });
      this.markers = [];
      const markerCluster = new MarkerClusterer(this.map, this.markers, {
        gridSize: 50,
        maxZoom: 15,
        imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      });
      if(this.currentLocation) {
        const infowindow = new google.maps.InfoWindow({
            content: "Your location",
            ariaLabel: "Your location"
          });
        const marker = new google.maps.Marker({
              position: { lat: this.currentLocation.lat, lng: this.currentLocation.lng },
              map: this.map,
              labelAnchor: new google.maps.Point(200, 100),
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 10,
                fillColor: "#0088FF",
                fillOpacity: 1,
                strokeColor: "white",
                strokeWeight: 2
              }
            });
        marker.addListener("click", () => {
            infowindow.open({
              anchor: marker,
              map,
            });
          });
      }
      if(this.renderContent?.length > 0) {
        this.addLocation(this.renderContent);
      }
      // if(this.entities.clubsData?.length > 0) {
      //   this.addLocation(this.entities.clubsData);
      // }
      // if(this.entities.associationsData?.length > 0) {
      //   this.addLocation(this.entities.associationsData);
      // }
      // if(this.entities.programsData?.length > 0) {
      //   this.addLocation(this.entities.programsData);
      // }
      // if(this.entities.teamsData?.length > 0) {
      //   this.addLocation(this.entities.teamsData);
      // }
      markerCluster.addMarkers(this.markers);
    },

    cloneDeep,
    handleCantFind() {
      // Expression of Interest for National Bodies
      this.$router
        .push({
          name: "expressinterest",
          params: { entityType: "national", entityId: 1 },
        })
        .catch(() => {});
    },
    submit(object) {
      this.updateCurrent({ entity: object });

      // Programs don't have regoOpen
      if (object.entityType === "program" && this.isAuthenticated) {
        this.$router.push({ name: "chooseprofile" });
      } else if (object.regoOpen && !object.teamInManyComps && this.isAuthenticated) {
        this.$router.push({ name: "chooseprofile" });
      } else if (object.regoOpen && !object.teamInManyComps) {
        this.$router
          .push({
            name: "loginrequired",
            params: { loginRedirect: "/register/chooseprofile" },
          })
          .catch((e) => {
            //   console.log("error");
          });
      } else if (object.entityType === "program") {
        this.$router
          .push({
            name: "loginrequired",
            params: { loginRedirect: "/register/chooseprofile" },
          })
          .catch((e) => {
            //  console.log("error");
          });
      } else {
        // Expression of Interest for Entities
        this.$router.push({
          name: "expressinterest",
          params: { entityType: object.entityType, entityId: object._id },
        });
      }
    },
    fetchSearchData() {
      this.cantFind = true;
      const nationalOrg = this.$store.getters["views/nationalOrg"];
      const body = omitBy(isEmpty)({
        source: nationalOrg ? nationalOrg.code : undefined,
        criteria: this.searchInput.split(",")[0],
        filters: omitBy(isEmpty)(
          Object.assign(
            {},
            this.appliedFiltersReal,
            { ages: this.currentAgeRange },
            { participantTypes: this.mappedParticipantType }
          )
        ),
        entityType: this.entityType
      });

      body.isEntityIdSearch = this.isEntityIdSearch;

      this.$store.commit('root/LOADING', true);

      this.$httpms.post("/searchtoPlayBeta", body)
        .then((response) => {
          if(this.isEntityIdSearch && this.entityType === "program") {
            this.searchInput = "";
          }
          if (this.isEntityIdSearch) this.isEntityIdSearch = false;
          const result = response.data;
          if (result && result.data && result.data.length !== 0) {
            this.entities = result.data;
          } else {
            this.entities = undefined;
          }
          this.$store.commit("root/LOADING", false);
        })
        .catch(() => {
          this.$store.commit(
            "views/PUSH_NOTIFICATION",
            {
              msg: msg.error.apiError,
              type: "warning",
            },
            { root: true }
          );
          this.entities = undefined;
          this.$store.commit("root/LOADING", false);
        });
    },
    querySearchAsync(queryString, cb) {
      const { searchResult } = this;
      const results = queryString
        ? searchResult.filter(this.createFilter(queryString))
        : searchResult;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        cb(results);
      }, 500 * Math.random());
    },
    createFilter(queryString) {
      return (link) => link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    },
    handleInput(item) {
      this.filterValue = item;
    },
    handleSubmitFilters(val) {
      this.appliedFiltersReal = cloneDeep(val);
      this.appliedFiltersSync = cloneDeep(val);
      this.toggleVisible(false);
      this.fetchSearchData();
    },
    handleCancelFilters() {
      this.appliedFiltersSync = cloneDeep(this.appliedFiltersReal);
      this.toggleVisible(false);
      this.fetchSearchData();
    },
    handleClearFilters() {
      this.appliedFiltersReal = cloneDeep(appliedFiltersOriginal);
      this.appliedFiltersSync = cloneDeep(appliedFiltersOriginal);
      this.toggleVisible(false);
      this.fetchSearchData();
    },
    toggleVisible(to) {
      this.filterVisible = to;
    },
  },
  mounted() {
    this.getCurrentGeoLocation();
    this.isEntityIdSearch = this.entity;
    const skeleton = cloneDeep(appliedFiltersOriginal);
    if (this.current.type) {
      switch (this.current.type) {
        case "player":
          skeleton.participantTypes.push("Player");
          break;
        case "coach":
          skeleton.participantTypes.push("Coach", "Trainer", "Volunteer");
          break;
        case "referee":
          skeleton.participantTypes.push("Referee");
          break;
        case "reregister":
        default:
      }
    } else {
      this.updateCurrent({ type: "player" });
    }
    if (this.criteria) {
      this.searchInput = this.criteria;
    }

    if (this.type) {
      const mappedTypes = memberTypes.filter((t) => this.type.includes(t.type)).map((t) => t.name);
      if (mappedTypes.length > 0) {
        skeleton.participantTypes.push(...mappedTypes);
        this.updateCurrent({ type: mappedTypes[0] });
      } else {
        this.updateCurrent({ type: "player" });
      }
    } else {
      this.updateCurrent({ type: "player" });
    }
    if (this.activity) {
      const foundActivities = this.activity.filter((param) => activity.includes(param));
      if (foundActivities.length > 0) {
        skeleton.activities.push(...foundActivities);
      }
    }
    if (this.gender) {
      const foundGender = gender.find((a) => a === this.gender);
      if (foundGender) skeleton.genders.push(foundGender);
    }
    if (this.agemin || this.agemax) {
      let ageLevel;
      if (this.agemin && this.agemax) {
        ageLevel = find((o) => o.min >= this.agemin && o.max <= this.agemax)(ageLevels);
      } else if (this.agemin) {
        ageLevel = find((o) => range(o.min, o.max + 1).includes(this.agemin))(ageLevels);
      } else if (this.agemax) {
        ageLevel = find((o) => range(o.min, o.max + 1).includes(this.agemax))(ageLevels);
      }
      if (ageLevel) skeleton.ages.push(ageLevel.name);
    }
    if (this.comptype) {
      const mappedCompTypes = this.comptype
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .filter((type) => competitionType.includes(type));
      if (mappedCompTypes.length > 0) {
        skeleton.competitionTypes.push(...mappedCompTypes);
      }
    }

    if (this.source) {
      // 'rugby-league' or 'touch-football'
      this.$store.dispatch("views/updateNationalOrg", this.source);
    }

    if(this.entitytype) {
      this.entityType = this.entitytype;
    }

    this.appliedFiltersOriginal = skeleton;
    this.appliedFiltersSync = skeleton;
    this.appliedFiltersReal = skeleton;

    if (this.searchInput) {
      this.fetchSearchData();
    }
  },
  data() {
    return {
      cantFind: false, // After search has been conducted
      // Search
      searchInput: "",
      searchTimeout: null,
      searchResult: [],
      // Filters
      filterVisible: false,
      filterValue: "",
      appliedFiltersOriginal,
      appliedFiltersSync: appliedFiltersOriginal, // Checkboxes
      appliedFiltersReal: appliedFiltersOriginal,
      lengthChoices,
      lengthValue: "",
      daysChoices,
      daysValue: [],
      entities: undefined,
      isEntityIdSearch: false,
      entityType: "",
      map: undefined,
      markers: [],
      infoWindow: undefined,
      currentLocation: undefined,
      activeTab: 0,
      renderContent: [],
      tabs: [
        { name: 'All' },
        { name: 'Programs' },
        { name: 'Clubs' },
        { name: 'Associations' },
        { name: 'Teams' },
      ],
    };
  },
};
</script>

<style lang="scss">
.text-days::before {
  content: var(--content);
  color: var(--color);
  text-transform: uppercase;
  position: absolute;
  font-family: $fontRegular;
  font-size: 0.875rem;
  top: 10px;
  left: 15px;
  size: 0;
  z-index: 1;
}
</style>

<style lang="scss" scoped>
.append-icon-close {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
  color: $secondary;
}

.has-value {
  // make it a cross
  ::v-deep i.icon-arrow-down {
    background: none;
  }

  i.icon-arrow-down {
    background: none;
  }

  ::v-deep .el-input__inner {
    height: calc(0.875rem + 26px);
    border-color: $secondary;
    color: $secondary;

    &::placeholder {
      color: $secondary !important;
    }
  }

  .el-input__inner {
    border-color: $secondary;

    &::placeholder {
      color: $secondary !important;
    }
  }
}

::v-deep .el-select {
  height: calc(0.875rem + 26px);
}

.el-input {
  .el-input__suffix {
    position: absolute;
    right: 8px;
    transition: 0.5s;
  }
  &.visible {
    .el-input__suffix {
      transform: rotate(-180deg);
    }
  }
}

.content {
  height: 90vh;
  overflow:auto;
}

.select-1 {
  position: relative;
  width: 100%;
  @media (min-width: $sm) {
    max-width: 250px;
  }
}

.select-2 {
  position: relative;
  width: 50%;
  @media (min-width: $sm) {
    max-width: 200px;
  }
}

.select-3 {
  position: relative;
  width: 50%;
  @media (min-width: $sm) {
    max-width: 200px;
  }
}

.filters {
  padding-left: 10px;
  flex-wrap: wrap;
}

.min-1000 {
  min-height: 1400px;
}

::v-deep .el-tag {
  display: none;
}

::v-deep .el-input-group__append {
  padding: 0;
  border: 0;
}

.icon-wrapper {
  height: 40px;
  width: 55px;
}

.mw-50p {
  width: 100%;
  @media (min-width: 1263px) {
    max-width: 50%;
  }
}
#map {
  height: 90vh;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
  position: relative;
}
.custom-map-control-button {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  height: 40px;
  cursor: pointer;
}
.custom-map-control-button:hover {
  background: rgb(235, 235, 235);
}
.root {
}

.header-block {
  box-sizing: border-box;
  background: #FFFFFF;
  border-bottom: 1px solid #EDEDED;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  height: 6em;
  padding-left: 1em;
}
.header-text {
  font-weight: 800;
  font-size: 20px;
  color: #222222;
  padding-top: 1em;
  // margin-bottom: 0;
}
.search-filter-root {
  padding-top: 2em;
}
.cant-find-card {
  margin-bottom: 2em;
}
.card-list-root {
  background: #F6F6F6;
  backdrop-filter: blur(4px);
  padding: 1.6em;
}
.sort-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.tabs {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-nav {
  display: flex;
}

.tab {
  cursor: pointer;
  margin-right: 10px;
  background: #E6E6E6;
  border-radius: 100px;
  // min-width: 36px;
  padding-right: 1em;
  padding-left: 1em;
  font-size: 0.8em;
  line-height: 2.4em;
}

.tab.active {
  background: #00DB00;
}

.tab-content {
  margin-top: 10px;
}
.grid-content {
    min-height: 36px;
  }
</style>
